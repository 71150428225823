<template>
  <v-container fill-height fluid class="profile pa-0" :class="{'mobile align-start': $vuetify.breakpoint.smAndDown}">
    <!-- <nav-logged ></nav-logged> -->
    <!--<mobile-nav v-if="$vuetify.breakpoint.smAndDown"></mobile-nav> -->
    <v-app-bar app>
      <v-toolbar-title>Edit Profile</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn color="red" text>
        Cancel
      </v-btn>

      <v-btn color="black" dark>
        Save
      </v-btn>
    </v-app-bar>

    <div align="center" justify="center" v-if="!$isEmpty(user)">
      <v-stepper
        v-model="e6"
        vertical
      >
        <v-stepper-step
          :complete="e6 > 1"
          step="1"
        >
          Select an app
          <small>Summarize if needed</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card
            color="grey lighten-1"
            class="mb-12"
            height="200px"
          ></v-card>
          <v-btn
            color="primary"
            @click="e6 = 2"
          >
            Continue
          </v-btn>
          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :complete="e6 > 2"
          step="2"
        >
          Configure analytics for this app
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card
            color="grey lighten-1"
            class="mb-12"
            height="200px"
          ></v-card>
          <v-btn
            color="primary"
            @click="e6 = 3"
          >
            Continue
          </v-btn>
          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :complete="e6 > 3"
          step="3"
        >
          Select an ad format and name ad unit
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card
            color="grey lighten-1"
            class="mb-12"
            height="200px"
          ></v-card>
          <v-btn
            color="primary"
            @click="e6 = 4"
          >
            Continue
          </v-btn>
          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="4">
          View setup instructions
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-card
            color="grey lighten-1"
            class="mb-12"
            height="200px"
          ></v-card>
          <v-btn
            color="primary"
            @click="e6 = 1"
          >
            Continue
          </v-btn>
          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>
      </v-stepper>
        <v-row class="white elevation-10 rounded-lg" no-gutters>
          <v-col cols="12" md="4" class="text-center profile-details bg-loop white--text pa-md-2">

            <div class="profile-photo pt-8 pt-md-0">
              <img :src="user.profileURL ? user.profileURL : user.basicAvatar" alt="" class="profile-img">
              <upload-image @uploaded-photo="setStoragePhoto"></upload-image>
            </div>
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <!-- <h2><inline-text-editor :initVal="user.name" :initField="'name'" :placeholder="'My Name'" :kind="'text'" @itChanged="itChanged"></inline-text-editor></h2>
              <p><inline-text-editor :initVal="user.title" :initField="'title'" :kind="'text'" :placeholder="'Professional Title'" @itChanged="itChanged"></inline-text-editor></p> -->
              <div class="company-links">
                <div><a href="#" class="white--text">@Keybot</a></div>
                <div><a href="#" class="white--text">@HighGravity</a></div>
              </div>

            </div>
          </v-col>
          <v-col cols="12" md="8" class="pa-md-4 pa-4">
            <v-row align="center" justify="space-between" no-gutters class="mb-2">
              <v-col cols="12">
                <h5 class="app-heading">Who are you?</h5>
                <v-row class="mb-2">
                  <v-col cols="6">
                    <v-text-field
                    placeholder="First Name"
                    filled hide-details="auto"
                    v-model="localUser.firstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                    placeholder="Last Name"
                    filled hide-details="auto"
                    v-model="localUser.lastName"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <h5 class="app-heading">A little about you 🤷<small class="text--disabled">*optional</small></h5>
                    <v-textarea filled hide-details="auto"></v-textarea>
                  </v-col>
                </v-row>
                <v-row  class="mb-2">
                  <v-col cols="12">
                    <h5 class="app-heading mb-3">What's your time worth?</h5>
                    <div class="bg-loop white--text d-flex align-center rounded justify-center py-2">
                      <v-icon color="white">mdi-currency-usd</v-icon>
                      <v-text-field dense single-line solo class="pa-0 ma-0 rate-field "></v-text-field>
                      <strong class="pl-2">/ 20 mins</strong>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>

            <v-divider class=" my-5"></v-divider>
            <h5 class="app-heading mb-3">What can people meet you about?</h5>
            <v-combobox
              v-model="skills"
              :items="skillsList"
              item-text="name"
              item-value="name"
              chips
              multiple
              clearable
              placeholder="Baking, Presentations, Cycling, ect..."
              filled
              return-object
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item.name }}</strong>&nbsp;
                </v-chip>
              </template>
            </v-combobox>
            <!-- <v-chip
              v-for="skill in skills" :key="skill.name"
              class="ma-2 pl-0"
              label
              small
              color="grey lighten-3"
              text-color="black"
              >
                <v-avatar tile left class="grey lighten-2 pl-4 pr-2">{{skill.amount}}</v-avatar>
                {{skill.name}}
                <v-icon right v-if="skill.rating > 3" color="green">mdi-menu-up</v-icon>
                <v-icon right v-if="skill.rating < 3" color="red">mdi-menu-down</v-icon>
            </v-chip> -->
          </v-col>
        </v-row>

      <!-- <v-col cols="12">
        <v-btn color="success" @click="addCalendar()">Add Calendar</v-btn>
        <iframe style="width:100%; height:70vh" :src="calAuthUrl" v-if="calAuthUrl" class="d-block"></iframe>
      </v-col> -->
    </div>



    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      dark
    >
      {{ snackbar.message }}
      <v-btn
        text
        color="white"
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>


  </v-container>
</template>

<script>
// const mainNav = () => import("@/components/shared/nav.vue");
// const mobileNav = () => import("@/components/shared/mobile-nav.vue");
// const navLogged = () => import("@/components/shared/nav-logged.vue");
// const avatarBuilder = () => import("@/components/shared/avatar-builder.vue");
const uploadImage = () => import("@/components/shared/upload-image.vue");
import { cloud, storage } from '@/firebase';
import { mapGetters } from 'vuex'

export default {
  name: "profile-mobile",
  components:{
    uploadImage,
  },
  computed:{
     getPercentages(arr){
        return true;
    },
    ...mapGetters({
      user:'GET_USER',
      userId:'GET_USER_ID',
    }),
  },
  watch:{
    skills: function (val, prev){
      let vThis = this;
      process.env.NODE_ENV === "development" ? console.log( val, prev ) : null;
      // if(val.length === prev.length) return
      if (val.length === prev.length) return
      let skills = val.map(v=>{
        process.env.NODE_ENV === "development" ? console.log('v........', v ) : null;
        if(typeof v === 'string'){
          v = {
            name: v,
            value: v.split(" ").join("")
          }
          // this.skillsList.push(v);
          // this.nonce++
        }
        return v
      })
      this.skills = skills;
    }
  },
  data(){
    return{
      skills: null,
      skillsList:[
        {name: 'HTML', amount: 10, rating:5},
        {name: 'Vue.js', amount: 8, rating:5},
        {name: 'UI', amount: 8, rating:5},
        {name: 'UX', amount: 6, rating:5},
        {name: 'Branding', amount: 6, rating:4},
        {name: 'Pizza', amount: 1, rating:4},
        {name: 'User Experience', amount: 6, rating:4},
        {name: 'Mobile Design', amount: 3, rating:3},
        {name: 'Brewing Beer', amount: 1, rating:2},

      ],
      calAuthUrl:false,
      isLoaded: false,
      localUser: {},
      snackbar:{
        show:false,
      },
      avatar: "https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Transparent&clotheColor=Gray02&clotheType=Hoodie&eyeType=Happy&eyebrowType=UpDownNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheMagnum&graphicType=Resist&hairColor=BrownDark&hatColor=Gray01&mouthType=Grimace&skinColor=Pale&topType=ShortHairShortWaved",
    }
  },
  methods:{
    async getCalAuth(){
        let getCalendarAuth = cloud.httpsCallable('getCalendarAuth');
        let token = await getCalendarAuth().then(function(result) {
          process.env.NODE_ENV === "development" ? console.log( 'results from getCalendarAuth', result ) : null;
          return result.data;
        }).catch(function(error) {
          process.env.NODE_ENV === "development" ? console.log( 'error', error ) : null;
        });
        return token
    },
    addCalendar(){
      let vThis = this;
      this.getCalAuth().then(url => {
        vThis.calAuthUrl = url;
      })
    },
    remove (item) {
        const index = this.skillsList.indexOf(item.name)
        if (index >= 0) this.skillsList.splice(index, 1)
      },
    setUp(){
      this.localUser = this.$clone(this.user);
      this.getStoragePhoto();
    },
    checkUserStatus(){
      let vThis = this;
      vThis.profileChecker = setInterval(() => {
        vThis.$store.dispatch('checkUserStatus').then((x)=>{
          process.env.NODE_ENV === "development" ? console.log( 'x',x ) : null;
          if(Object.hasOwnProperty.call(x, 'account')){
            clearInterval(vThis.profileChecker)
          }
        })
      },1000);

    },
    finalizeSetup(){
      process.env.NODE_ENV === "development" ? console.log( Object.hasOwnProperty.call(this.user, 'avatar') ) : null;
      if(!Object.hasOwnProperty.call(this.user, 'avatar')){
        this.saveAvatar(this.localUser,true)
      }
      else{

        this.$router.push({name:'build'})
      }
    },
    getStoragePhoto(){
      let vThis = this;
      storage.ref(`${this.userId}/profile_photo/profile`).getDownloadURL().then(data=>{
        vThis.setStoragePhoto(data);
        process.env.NODE_ENV === "development" ? console.log('profile photo',  data) : null;
      }).catch(error => {
        process.env.NODE_ENV === "development" ? console.log( 'error', error.code ) : null;
      });
    },
    setStoragePhoto(url){
      this.profileURL = url;
    },
    itChanged(data){
      process.env.NODE_ENV === "development" ? console.log( 'changed', data ) : null;
      this.user[data.field] = data.text
    },
  },
  mounted(){
    let vThis = this;
    // if(this.$vuetify.breakpoint.mdAndUp){
    //   this.$store.dispatch('toggleDrawer', true);
    // }
    if(Object.hasOwnProperty.call(this.$route.query, 'newAccount')){
      this.showNewAccount = true;
      process.env.NODE_ENV === "development" ? console.log( 'profile user', this.$isEmpty(this.$store.state.user) ) : null;
      process.env.NODE_ENV === "development" ? console.log( 'profile user', this.user ) : null;
      if(this.user == undefined || this.$isEmpty(this.user)){
        process.env.NODE_ENV === "development" ? console.log( 'no user found' ) : null;
        this.checkUserStatus();
      }
    }
    else{
      // process.env.NODE_ENV === "development" ? console.log( !this.$isEmpty(this.user) ) : null;
      if(!this.$isEmpty(this.user)){
        this.setUp(this.user)
      }
    }


  }
};
</script>


<style lang="scss">
.profile{
  background-color: #f2f2f2;
  position: relative;
  height: 100vh;
  padding-top: 70px;
  // padding-left: 280px;
  .profile-details{
    .profile-img{
      max-width: 100%;
      border-radius: 6px;
      border: 3px solid white;
    }
    h2{
      line-height: 95%;
    }
    .company-links{
      text-decoration: none;
    }
  }
  .rate-field{
    min-width: 40px;
    max-width: 60px!important;
    .v-text-field__details{
      display: none;
    }
    .v-input__slot{
      padding: 0 5px!important;
      margin:0!important;
      input{
        // text-align:right;
      }
    }
  }
  .v-card{
    .v-card__text{
      position: relative;
      .editBtn{
        position: absolute;
        right: 30%
      }
    }
  }
  &.mobile{
    // padding-top:0;
    padding-left: 0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          // padding: 0;
        }
      }
      .v-card{
        padding-bottom: 12px;
      }
    }
    .profile-photo{
      max-width: 50vw;
      margin: 0 auto;
    }
  }
}
</style>
